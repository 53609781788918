import { getSourceAcquisitions } from "~/layers/company/services/useSourceAcqusition"

export const company = "/company"
export const settingOptions = "/company/setting-options"
export const service = "/company/service"
export const estimateCost = "/company/estimate-cost"
export const milestoneTemplate = "/company/milestone-template"
export const scope = "/company/scope"
export const projectStatus = "/company/project-status"
export const bankAccount = "/company/bank-account"
export const taxCompany = "/company/tax-company"
export const sourceAcquisition = "/company/source-acquisition"
